@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6;
}

pre {
  font-family: 'Open Sans', sans-serif;
}

html {
  margin: 0;
}

.innerTable td {
  padding: 20px;
  
}

.innerTable td:not(:first-child) {
  text-align: center;
}

  .middle {
    position: absolute;
    bottom: 5vh;
    transform: translate(-50%, -50%);
    left: 50%;
    opacity: 1;
  }

  .middle--hidden {
    opacity: 0;
  }

  .mouse {
    width: 30px;
    height: 50px;
    border: 2px solid #333;
    border-radius: 60px;
    opacity: 0.5;
  }

  .mouse::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: 30px;
    background: #333;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    opacity: 1;
    animation: mouse 1.5s infinite;
  }

  @keyframes mouse {
    from {
      opacity: 0.8;
      top: 15px;

    }
    to {
      opacity: 0;
      top: 35px;
    }
  }

  .nav {
    background-color: clear;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 150px;
    z-index: 1000;
    margin: 0;
    padding: 0;
  }
  
  .nav-logo {
    width: 100px;
    height: 26px;
  }
  
  .nav-logo:hover {
    cursor: pointer;
  }
  
  .nav .nav-content {
    max-width: 95vw;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    outline:none;
  }
  
  .nav-item {
    display: none;
    margin-left: 2rem;
    color: #333;
    outline:none;
  }
  
  .nav-item:hover {
    color: black;
    cursor: pointer;
  }
  
  /* Section Universal */
  .section {
    width: 100vw;
    color: white;
  }
  
  .section-dark {
    color: black;
  }
  
  .section-content {
    max-width: 70vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
    height: -webkit-min-content;
    height: min-content;
    margin: 0 auto;
  }

  .section span {
    font-weight: bold;
  }

  .section h1 {
    font-size: 5.5vmin;
  }

  .section p {
    font-size: 1.5vmin;
  }

  .line-dark {
    background: black;
    width: 100%;
    height: 1px;
    position: relative;
    display: block;
    margin: 50px 0;
  }

  .line {
    background: white;
    width: 100%;
    height: 1px;
    position: relative;
    display: block;
    margin: 50px 0;
  }

  a {
    text-decoration: none;
    color: black;
    transition: all 0.3s ease-in-out;
    transform:scale(1);
  }

  a:hover {
    text-shadow:
    -.2px -.2px 0 black,
    .2px -.2px 0 black,
    -.2px .2px 0 black,
    .2px .2px 0 black;
    transform:scale(1.2);
  }
  /* Section 1 */

  #section1 {
    height: 100vh;
    overflow-y: hidden;
  }

  #section1 h2 {
    font-size: 5vmin;
    color: #B8B8B8;
  }

  #section1 span {
    cursor: pointer;
  }

  #section1 button {
    font-size: 5vmin;
    color: black;
    position: relative;
    padding: 0;
  }

  #section1 .btn {
    border: none;
    background: #F6F6F6;
    color: black;
    outline:none;
  }

  #section1 .btn-text {
    color: white;
    mix-blend-mode: difference;
  }

  #section1 .btn:before {
    transition: all 0.85s cubic-bezier(0.68, -0.15, 0.265, 1.15);
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  #section1 .btn:hover + .section-bg {
    opacity: 1;
    transform: scale(1.1);
    transition: all 1s ease-in-out;
  }

  #section1 .btn:hover:before {
    background: black;
    width: 100%;
  }

  .section-text {
    display: inline;
  }

  .section-bg {
    opacity: 0;
    min-width: 110vw;
    min-height: 110vh;
    left: calc(-50vw + 50%);
    top: calc(-30vh);
    position: absolute;
    z-index: -1000;
  }

  .section-bg.one {
    background: url(/static/media/bg3.2d3f4568.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .section-bg.two {
    background: url(/static/media/bg4.f8c9e88d.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .section-bg.three {
    background: url(/static/media/bg6.5c51af73.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .section-bg.four {
    background: url(/static/media/bg5.7f47f225.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  .section-bg.five {
    background: url(/static/media/bg2.5ff8b375.jpg) no-repeat center center fixed;
    background-size: cover;
  }

  /* Section 2 */

  #section2 {
    background-color: #0091FF;
  }

  .sessionPrices li {
    list-style-type: none;
    display: block;
    float: left;
    font-weight: bold;
    font-size: 2vmin;
    width: 80px;
    padding-right: 10vw;
    padding-bottom: 2vh;
  }

  .sessionPrices ul {
    margin-left: -40px;
  }

  .sessionPrices span {
    font-size: 5vmin;
  }

  .sessionPrices.two li {
    width: 150px;
  }

  .sessionPrices p {
    width: 50vmin;
  }

  .sessionTimes td {
    text-align: center;
    column-width: 16.5vw;
    font-size: 1.6vmin;
    background-color: white;
    border-radius: 20px;
    padding: 20px 50px;
    color: black;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: lighter;
  }

  .sessionTimes table { 
    border-collapse:separate; 
    border-spacing:0 15px;
    margin: 0 auto;
  } 

  .expressTable {
    display: grid;
    justify-content: center;
    width: 100%;
  }

  .sessionTimes td span {
    font-weight: bold;
    font-style: normal;
  }

  .colspan {
    font-style: italic;
  }

  .halloween {
    object-fit: cover;
    max-width: 100%;
  }

  .box {
    display: none;
  }

  .cal ul {
    list-style: none;
    
  }

  .cal li {
    display: inline-block;
    margin-right: 10px;
  }

  .dayBtn {
    background: none;
    border: 1px solid white;
    width: 5vw;
    height: 5vw;
    border-radius: 100%;
    font-size: 3vmin;
    color: white;
    text-align: center;
    outline:none;
  }

  .dayBtn span {
    display:inline-block;
    vertical-align:middle;
    max-width:90%;
  }

  .dayBtn:before {
    content:'';
    display:inline-block;;
    vertical-align:middle;
    padding-top:100%;
  }

  .dayBtn:hover{
    cursor: pointer;
    background-color: white;
    color: black;
  }

  .dayBtn::after {
    background-color: white;
    color: black;
  }

  .dayBtn.selected {
    background-color: white;
    color: black;
  }

  .holidaytimes {
    margin-top: 30px;
    width: 100%;
    height: 40%;
    object-fit: cover;
  }


  /* Section 3 */

  #section3 h3 span {
    font-size: 5vmin;
  }

  #section3 h3 {
    font-size: 3vmin;
    margin-bottom: 20px;
  }

  #section3 p2 {
    font-size: 2vmin;
  }

  #section3 p3 {
    font-size: 2vmin;
    text-align: right;
    float: right;
  }

  /* Section 4 */
  #section4 {
    background-color: #13C6A4;
  }

  #section4 h4 span {
    font-size: 3.8vw;
  }

  #section4 h3 span {
    font-size: 5vmin;
  }

  #section4 h3 {
    font-weight: 500;
    font-size: 3vmin;
    margin-bottom: 20px;
  }

  #section4 p2 {
    font-size: 2vmin;
  }

  .learntimes {
    display: grid;
    place-items: center;
    text-align: center;
    line-height: 0.3vh;
    max-width: 70vw;
    white-space: nowrap;
  }

  .learntimes.mid {
    margin: 20px 0;
  }

  .learntimes p2 {
    margin: 10px 0;
  }

  /* Section 5 */

  #section5 h3 span {
    font-size: 5vmin;
  }

  #section5 h3 {
    font-size: 3vmin;
    margin-bottom: 20px;
  }

  #section5 p2 {
    font-size: 2vmin;
  }
  
  /* Section 6 */
  #section6 {
    background-color: #FFE658;
    padding-bottom: 100px;
  }

  #map {
    height: 400px;
    width: 100%;
    background: rgba(0,0,0,0.1);
    border: 0;
    filter: grayscale(100%);
  }

  #section6 p3 {
    font-size: 2vmin;
    text-align: right;
    float: right;
  }

  #socialmedia {
    float: right;
  }

  .socialbtn {
    width: 20px;
    height: 20px;
    margin-left: 30px;
    transition: all 0.5s ease;
    transform: scale(1);
    cursor: pointer;
    opacity: 0.8;
  }

  .socialbtn:hover {
    transform: scale(1.2);
    opacity: 1;
  }

  .nav-logo2 {
    width: 100px;
    height: 25px;
  }

  .farewellDiv{
    position: absolute;
    width: 80vw;
    top: 120px;
    margin-left: 10vw;
  }

  .farewellDiv p {
    font-size: 1.2em;
    line-height: 1.6em;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .nav {
      background-color: clear;
      position: fixed;
      bottom: 0px;
      width: 80px;
      height: 40px;
      z-index: 1000;
      margin: 0 auto;
      margin-left: -40px;
      padding: 0;
      left: 50%;
    }

    .nav .nav-content {
      max-width: 80px;
      
      margin: 0 auto;
      display: block;
      height: 100%;
    }
    
    .halloween {
      object-fit: cover;
      max-width: 100%;
    }

    .nav-logo {
      width: 100px;
      height: 25px;
      margin-top: 20px;
      margin-left: -60px;
    }

    .middle {
      visibility: hidden;
    }

    .section-content {
      max-width: 90vw;
      padding-top: 5vh;
      padding-bottom: 5vh;
    }

    .section h1 {
      text-align: center;
      font-size: 6vmin;
    }

    .section p {
      font-size: 4vmin;
    }

    /* Section 1 */
    #section1 h2 {
      font-size: 6vmin;
    }

    #section1 button {
      font-size: 6vmin;
    }

    /* Section 2 */
    .sessionPrices li {
      font-size: 3vmin;
      padding-right: 30vw;
    }

    .sessionPrices span {
      font-size: 10vmin;
    }

    .cal {
      width: 100vw;
      margin-left: -5vw;
    }

    .cal p {
      text-align: center;
    }

    .sessionTimes td {
      text-align: center;
      font-size: 3vmin;
      background-color: white;
      border-radius: 20px;
      padding: 15px 50px;
      color: black;
      margin-bottom: 20px;
      font-style: italic;
      font-weight: lighter;;
    }

    .sessionTimes {
      width: 90vw;
    }

    .dayBtn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 4vmin;
      text-align: center;
      padding: 0;
    }

    /* Section 3 */
    #section3 h3 span {
      font-size: 10vmin;
    }

    #section3 h3 {
      font-size: 6vmin;
    }

    #section3 p2 {
      font-size: 4vmin;
    }

    #section3 p3 {
      font-size: 4vmin;
    }

    /* SEction 4 */
    #section4 h3 span {
      font-size: 10vw;
    }
  
    #section4 h3 {
      font-size: 6vmin;
    }

    #section4 p2 {
      font-size: 4vmin;
    }

    #section4 h4 span {
      font-size: 8vmin;
    }

    .learntimes {
      max-width: 90vmin;
    }

    /* Section 5 */
    #section5 h3 span {
      font-size: 10vmin;
    }
  
    #section5 h3 {
      font-size: 6vmin;
      margin-bottom: 20px;
    }
  
    #section5 p2 {
      font-size: 4vmin;
    }

    /* Section 6 */
    #section6 p3 {
      font-size: 4vmin;
    }

    .socialbtn {
      width: 30px;
      height: 30px;
      margin-left: 30px;
    }

  }
