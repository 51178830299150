@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6;
}

pre {
  font-family: 'Open Sans', sans-serif;
}

html {
  margin: 0;
}

.innerTable td {
  padding: 20px;
  
}

.innerTable td:not(:first-child) {
  text-align: center;
}